.footer {
  width: 100%;
  padding: 80px 0 50px 0;
  background-color: #2d2d2d;

  &--inner {
    max-width: 1440px;
    margin: 0 auto;
    display: flex;

    &-column {
      flex: 1;

      & .contaction {
        margin-left: 112px;

        & .title {
          color: #fff;
          font-size: 20px;
        }

        & .description {
          color: #fff;
          font-size: 14px;
          line-height: 20px;
          margin-top: 20px;
        }

        & .more {
          margin-top: 20px;
          display: flex;

          & .logo {
            width: 50px;
            height: 50px;
            display: flex;
            justify-content: center;
            align-items: center;
            border-radius: 25px;
            border: 1px solid #e3e3e3;

            &:not(:first-child) {
              margin-left: 15px;
            }

            & img {
              width: 20px;
              height: 20px;
            }
          }
        }

        & .links {
          margin-top: 60px;
          color: #fff;
          font-size: 14px;

          & a {
            color: #fff;
            margin: 0 5px 0 5px;
            text-decoration: none;
          }
        }
      }

      & .company, & .service {
        margin-left: 233px;

        & .title {
          font-size: 16px;
          color: #aeaeae;
        }

        & .catalog {
          margin-top: 38px;

          & a {
            display: block;
            color: #fff;
            font-size: 14px;
            text-decoration: none;

            &:not(:first-child) {
              margin-top: 15px;
            }
          }
        }
      }
    }
  }
}