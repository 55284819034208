nav ul {
  list-style: none;
	margin: 0 120px 0 0;
	height: 100%;
	display: flex;
	align-items: center;

	& li {
		&:not(:first-child) {
			margin-left: 60px;
		}

		& a {
			color: #101010;
			text-decoration: none;
		}
		&.selected a {
			color: #3894FF;
		}
	}
}