.header {
  width: 100%;
	height: 120px;
	background-color: #fff;

	&--inner {
		max-width: 1440px;
		height: 100%;
		display: flex;
		margin: 0 auto;
		align-items: center;

		& .logo {
			width: 80px;
			height: 80px;
			margin-left: 40px;
		}

		& .title {
			margin-left: 10px;
			display: flex;
			align-items: center;
			color: #3894ff;
			font-size: 36px;
		}

		& .navigation {
			flex: 1;
			display: flex;
			align-items: center;
			justify-content: flex-end;
		}
	}
}