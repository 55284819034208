.banner {
  width: 100%;
  position: relative;

  &__background {
    position: absolute;
    top: 0;
    left: 0;
    bottom: 0;
    right: 0;
    filter: blur(6px);
  }

  &__content {
    max-width: 1440px;
    margin: 0 auto;
    position: relative;

    &_img {
      width: 100%;
    }
    &_detail {
      position: absolute;
      top: 0;
      left: 0;
      bottom: 0;
      right: 0;
      display: flex;
      align-items: center;

      &_card {
        margin-left: 120px;

        & .title {
          font-size: 60px;
          color: #fff;
        }

        & .description {
          font-size: 20px;
          color: #fff;
          margin-top: 25px;
        }

        & .button-more {
          margin-top: 52px;
        }
      }
    }
  }
}