.business-support {
  background-color: #f2f2f2;
  max-width: 1440px;
  margin: 0 auto;

  &__header {
    font-size: 28px;
    text-align: center;
    padding: 95px 0 55px 0;
  }
  &__content {
    &__row {
      font-size: 0;
      text-align: center;

      & img {
        &:not(:first-child) {
          margin-left: 20px;
        }
      }

      &:not(:first-child) {
        margin-top: 20px;
      }
    }
  }
  &__footer {
    padding: 59px 0 110px 0;
    text-align: center;

    & .button-more {
      width: 260px;
      height: 50px;
      line-height: 50px;
      font-size: 14px;
      background-color: transparent;
    }
  }
}