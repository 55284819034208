.apply-list {
  max-width: 1440px;
  margin: 0 auto;

  &__header {
    color: #101010;
    font-size: 28px;
    text-align: center;
    margin: 95px 0 55px 0;
  }
  &__content {
    margin-bottom: 115px;

    &__row {
      display: flex;
      justify-content: space-around;

      &__item {
        border: 1px solid #bbb;
        display: flex;
        justify-content: center;
        align-items: center;
        width: 220px;
        height: 140px;

        & img {
          width: 200px;
          height: 120px;
        }
      }

      &:not(:first-child) {
        margin-top: 60px;
      }
    }
  }
}