.service-promise {
  background-color: #3d3d3d;
  text-align: center;
  overflow: hidden;

  & .title {
    margin-top: 50px;
    font-size: 36px;
    color: #fff;
  }
  & .description {
    font-size: 20px;
    color: #fff;
    margin-top: 10px;
  }
  & .button-more {
    margin: 42px 0 65px 0;
  }
}