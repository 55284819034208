.shop-window {
  max-width: 1440px;
  margin: 110px auto 0 auto;

  &__item {
    display: flex;
    box-sizing: border-box;
    padding: 0 120px;
    margin-bottom: 95px;

    & img {
      width: 900px;
      height: 415px;
    }

    & .introduction {
      flex: 1;
      box-sizing: border-box;
      max-width: 500px;
      &.margin {
        margin-left: 60px;
      }

      & .title {
        margin-top: 69px;
        font-size: 28px;
        color: #101010;
      }
      & .sub-title {
        margin-top: 13px;
        font-size: 18px;
        color: #aeaeae;
      }
      & .description {
        font-size: 18px;
        color: #101010;
        margin-top: 25px;
      }
      & .button-more {
        margin-top: 30px;
      }
    }
  }
}