.learn-more-button {
  cursor: pointer;
  border: 1px solid #3894ff;
  width: 160px;
  height: 50px;
  line-height: 50px;
  text-align: center;
  border-radius: 5px;

  &.default {
    background-color: #fff;
    color: #3894ff;
  }

  &.primary {
    background-color: #3894ff;
    color: #fff;
  }
}